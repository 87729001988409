import React from "react"
import { graphql } from "gatsby"
import styles from "../css/product-template.module.css"
import Layout from "../components/layout-component"
import Product from "../components/product-template"
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"

const ProductTemplate = ({ data }) => {
  const intl = useIntl()
  const {
    nombre,
    descripcion: { descripcion },
    imagenes,
    etiquetas,
    precios,
  } = data.singleProduct

  return (
    <Layout>
      <SEO
        title={`${intl.formatMessage({ id: "titleProducto" })}`}
        description={`${intl.formatMessage({ id: "descProduct" })}`}
        keywords={[
          `${intl.formatMessage({ id: "ProductKeyword1" })}`,
          `${intl.formatMessage({ id: "ProductKeyword2" })}`,
        ]}
        lang={intl.locale}
      />
      <section className={styles.template}>
        <Product
          images={imagenes}
          etiquetas={etiquetas}
          descripcion={descripcion}
          nombre={nombre}
          precios={precios}
        />

        <div className={styles.center}>
          <Link to="/galeria" className={`btn-blue ${styles.button}`}>
            <FormattedMessage id="irAlaGaleria" />
          </Link>

          <Link to="/contact" className={`btn-primary ${styles.button}`}>
            <FormattedMessage id="preguntar" />
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($locale: String, $slug: String!) {
    singleProduct: contentfulBravohatsData(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      nombre
      descripcion {
        descripcion
      }
      etiquetas {
        etiqueta
      }
      precios {
        calidad
        precio
      }
      imagenes {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default ProductTemplate
