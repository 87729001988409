import React, { useState } from "react"
import styles from "../css/product-component-template.module.css"
import Img from "gatsby-image"
import { FaTags } from "react-icons/fa"
import Tallas from "../constants/tallas"
import { FormattedMessage } from "gatsby-plugin-intl"
const ImageGrid = ({ images, etiquetas, descripcion, nombre, precios }) => {
  const [mainImage, setmainImage] = useState(0)

  const handleImage = index => {
    setmainImage(index)
  }
  return (
    <section className={styles.cardWrapper}>
      <div className={styles.productContainer}>
        <div className={styles.imagesWraper}>
          <div className={styles.mainImage}>
            <Img fluid={images[mainImage].fluid} />
          </div>
          <div className={styles.otherImages}>
            {images.map((item, index) => {
              return (
                <div
                  onKeyDown={null}
                  role="button"
                  tabIndex="0"
                  key={index}
                  onClick={() => handleImage(index)}
                >
                  <Img fluid={item.fluid} className={styles.other} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className={styles.productInfo}>
        <h1>{nombre}</h1>
        <div className={styles.tags}>
          {" "}
          <FaTags className={styles.iconTag} />
          {etiquetas.map((item, index) => {
            return (
              <h6 key={index} className={styles.tag}>
                {item.etiqueta}
              </h6>
            )
          })}
        </div>
        <p className={styles.description}>{descripcion}</p>
        <h3>
          <FormattedMessage id="calidadesproduct" />
        </h3>
        <div className={styles.calidades}>
          {precios.map((item, index) => {
            return (
              <p key={index} className={styles.mainCalidad}>
                {item.calidad}
              </p>
            )
          })}
        </div>
        <h3>
          <FormattedMessage id="tallasproduct" />
        </h3>
        <div className={styles.tallas}>
          {Tallas.map((item, index) => {
            return <p key={index}>{item.title}</p>
          })}
        </div>

        <h3>
          <FormattedMessage id="coloresproduct" />
        </h3>
        <div className={styles.colores}>
          <div className={`${styles.color} ${styles.verde}`}></div>
          <div className={`${styles.color} ${styles.cafe}`}></div>
          <div className={`${styles.color} ${styles.beige}`}></div>
          <div className={`${styles.color} ${styles.rojo}`}></div>
          <div className={`${styles.color} ${styles.blanco}`}></div>
          <div className={`${styles.color} ${styles.negro}`}></div>
          <div className={`${styles.color} ${styles.azul}`}></div>
          <div className={`${styles.color} ${styles.celeste}`}></div>
          <div className={`${styles.color} ${styles.naranja}`}></div>
        </div>
        <h3>
          <FormattedMessage id="preciosproduct" />
        </h3>
        <div className={styles.precios}>
          {precios.map((item, index) => {
            return <p key={index}>$ {item.precio}</p>
          })}
        </div>
      </div>
    </section>
  )
}

export default ImageGrid
